.sun {
  position: absolute;
  left: 25%;
  transform: translateX(-50%);
  top: 80%;
  /* height:15vh; */
  height: 75px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: rgb(255, 235, 230);
  opacity: 0.9;
  box-shadow: 0px 0px 40px 15px rgb(255, 255, 255);
}

.mountains {
  /* animation: sidescroll_anim 60s linear infinite;
    -webkit-animation: sidescroll_anim 60s linear infinite; */
}

.trees::before {
    background-image: url(./images/trees.webp);
    transform: translate3d(0,0,0);
  -webkit-transform:translate3d(0,0,0);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
}
.trees {
  animation: trees_sidescroll 2s linear infinite;
  transform: translate3d(0,0,0);
  -webkit-transform:translate3d(0,0,0);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  /* -webkit-animation: web_trees_sidescroll 3s linear infinite; */
}

.ray_box {
  position: absolute;
  margin: auto;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 70px;
  -webkit-animation: ray_anim 120s linear infinite;
  animation: ray_anim 120s linear infinite;
}
.ray {
  background-color: rgb(255, 81, 0);
  background: -webkit-linear-gradient(
    to top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  margin-left: 10px;
  border-radius: 80% 80% 0 0;
  position: absolute;
  opacity: 0.1;
}

.ray1 {
  height: 170px;
  width: 30px;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  top: -175px;
  left: 15px;
}
.ray2 {
  height: 100px;
  width: 8px;
  transform: rotate(220deg);
  -webkit-transform: rotate(220deg);
  top: -90px;
  left: 75px;
}
.ray3 {
  height: 170px;
  width: 50px;
  transform: rotate(250deg);
  -webkit-transform: rotate(250deg);
  top: -80px;
  left: 100px;
}
.ray4 {
  height: 120px;
  width: 14px;
  transform: rotate(305deg);
  -webkit-transform: rotate(305deg);
  top: 30px;
  left: 100px;
}
.ray5 {
  height: 140px;
  width: 30px;
  transform: rotate(-15deg);
  -webkit-transform: rotate(-15deg);
  top: 60px;
  left: 40px;
}
.ray6 {
  height: 90px;
  width: 50px;
  transform: rotate(30deg);
  -webkit-transform: rotate(30deg);
  top: 60px;
  left: -40px;
}
.ray7 {
  height: 180px;
  width: 10px;
  transform: rotate(70deg);
  -webkit-transform: rotate(70deg);
  top: -35px;
  left: -40px;
}
.ray8 {
  height: 120px;
  width: 30px;
  transform: rotate(100deg);
  -webkit-transform: rotate(100deg);
  top: -45px;
  left: -90px;
}
.ray9 {
  height: 80px;
  width: 10px;
  transform: rotate(120deg);
  -webkit-transform: rotate(120deg);
  top: -65px;
  left: -60px;
}
.ray10 {
  height: 190px;
  width: 23px;
  transform: rotate(150deg);
  -webkit-transform: rotate(150deg);
  top: -185px;
  left: -60px;
}

@keyframes ray_anim {
  0% {
    transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes ray_anim {
  0% {
    -webkit-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@keyframes trees_sidescroll {
  0% {
    -webkit-transform: translate3d(0%, 0, 0) ;
  }
  100% {
    -webkit-transform: translate3d(-25%, 0, 0);
  }
}

@-webkit-keyframes web_trees_sidescroll {
  0% {
    -webkit-transform: translateX(0%) translateZ(0);
  }
  100% {
    -webkit-transform: translateX(-25%) translateZ(0);
  }
}
