@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url(https://fonts.googleapis.com/css2?family=GFS+Neohellenic:wght@700&display=swap); */
@import url(https://fonts.googleapis.com/css2?family=Caesar+Dressing&display=swap);
/* @import url(https://fonts.googleapis.com/css2?family=Paytone+One&display=swap); */
/* @import url('https://fonts.googleapis.com/css2?family=Metal+Mania&display=swap'); */


html {
    user-select: none;
}